
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import TeamMemberPhoto from '../generic/TeamMemberPhoto'

class OurTeamItem extends BoundComponentBase {
    constructor(props) {
        super(props)
        this.employeeNameRef = "home_our_team_" + this.props.employeeId + "_name";
        this.employeeImageUrlRef = "home_our_team_" + this.props.employeeId + "_image_url";
        this.employeeDesc = "home_our_team_" + this.props.employeeId + "_description";
        this.employeeLinkedIn = "home_our_team_" + this.props.employeeId + "_linkedIn";
    }
     

    render() {
        return (
            <div class="home-our-team-item">
                <TeamMemberPhoto employeeId={ this.props.employeeId } />
                <p id={this.employeeNameRef} contentEditable={this.props.global.editing}
                    onPaste={OurTeamItem.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global[this.employeeNameRef] }} />
                <a href={this.props.global[this.employeeLinkedIn]} target="_blank" rel="noopener">
                    <img src="/images/linkedInLink.jpg" alt="Grey LinkedIn Icon" />
                </a> 
            </div >
        );
    }

}

export default connect(OurTeamItem.mapStateToProps)(OurTeamItem);

