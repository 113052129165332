import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import app from "../../dataStore/firebaseStorage";
import EnvironmentSetting from "../../dataStore/environmentSetting";
//import { getAuth, signInWithPopup,   } from "firebase/auth";

class HeaderItem extends BoundComponentBase {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.style = { position: props?.backing ? "static" : "fixed" };
        this.menuItems = [
            {
                title: "Home",
                url: "/"
            },
            {
                title: "About Us",
                url: "/about-us"
            },
            {
                title: "What we do",
                url: "/what-we-do"
            },
            {
                title: "Our Team",
                url: "/our-team"
            },
            {
                title: "News",
                url: "/news"
            },
            {
                title: "Contact Us",
                url: "/contact-us"
            },
        ];
    }


    handleChange(event) {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    }

    publish = () => {

        app.auth().signInWithEmailAndPassword(EnvironmentSetting().user(this.state.username), EnvironmentSetting().pxw(this.state.password))
            .then((result) => {
                app.storage().ref(`/siteContent.json`).put(new File([JSON.stringify(this.props.global)], 'siteContent.json', { type: "text/plain" }), { type: "text/plain" })
                    .then(p => {

                        this.props.global.editing = false;
                        this.props.global.editingMode = false;
                        this.pushModel();
                        alert("Site Updated");

                        window.location = '/';
                    })
                    .catch(p => {
                        this.setState({ ...this.state, password: "" });
                        alert("Login Failed");
                    });

            }).catch((error) => {
                this.setState({ ...this.state, password: "" });
                alert("Login Failed");
                // ...
            });
    }


    editClicked = () => {
        this.props.global.editing = !this.props.global.editing;
        this.pushModel();
    }

    render() {
        return (
            <div className={this.props.class + ' header'} style={this.style}>

                <div class="header_item_logo">
                    <Link to="/"><img src="/images/smallLogo.jpg" class="logo_standard" /></Link>
                </div>
                <div class="header-menu">
                    <ul>
                        {this.menuItems.map((item) => (
                            <li>
                                <Link to={item.url}>{item.title}</Link>
                            </li>
                        ))}
                        <li class="header-social-media">
                            <a target="_blank" rel="noopener" href="https://www.facebook.com/VerbankAdvisors/">
                                <img src="/images/facebook.jpg" />
                            </a>
                            <a target="_blank" rel="noopener" href="https://twitter.com/Verbank_">
                                <img src="/images/twitter.jpg" />
                            </a>
                            <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/verbankadvisors">
                                <img src="/images/linkedIn.jpg" />
                            </a>
                            <a target="_blank" rel="noopener" href="https://www.instagram.com/verbankadvisors/">
                                <img src="/images/instagram.jpg" />
                            </a>
                        </li>
                        {this.props.global.editingMode &&
                            <li>
                            {!EnvironmentSetting().isDev &&
                                    <>
                                        <input name="username" placeholder="Username" onChange={this.handleChange} />
                                        <input name="password" placeholder="Password" type="password" onChange={this.handleChange} value={this.state.password} />
                                    </>}
                                <button onClick={this.publish} >Publish</button >
                            </li>
                        }    </ul>
                </div>
                <div style={{ clear: "both" }}> </div>
            </div>
        );
    }
}

export default connect(HeaderItem.mapStateToProps)(HeaderItem);