
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import WhatWeDoItem from './WhatWeDoItem'

class WhatWeDo extends BoundComponentBase {

    render() {
        return (
            <div class="home-what-we-do" >
                <h2 id="home_what_we_do_heading" contentEditable={this.props.global.editing}
                    onPaste={WhatWeDo.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_what_we_do_heading }} />
                <div class="home-what-we-do-items" >
                    <WhatWeDoItem itemId="1"/>
                    <WhatWeDoItem itemId="2"/>
                    <WhatWeDoItem itemId="3"/>
                </div>
                <p class="centred-button">
                    {this.props.global.editing ?
                        <a class="customButton" id="home_what_we_do_button" contentEditable="true" onPaste={WhatWeDo.cleanse}  onBlur={this.handleEdit}
                            dangerouslySetInnerHTML={{ __html: this.props.global.home_what_we_do_button }} /> :
                        <Link class="customButton" to="/what-we-do" dangerouslySetInnerHTML={{ __html: this.props.global.home_what_we_do_button }} />
                    }

                </p>
            </div>
        );
    }
}

export default connect(WhatWeDo.mapStateToProps)(WhatWeDo);
