
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'


class Description extends BoundComponentBase {
    render() { 
        return (
            <div class="home-description" >
                <p id="home_description_para_1" contentEditable={this.props.global.editing}
                    onPaste={Description.cleanse}  onBlur={this.handleEdit} class="home-description-occupy-niche"
                    dangerouslySetInnerHTML={{ __html: this.props.global.home_description_para_1 }} />
                <p id="home_description_para_2" contentEditable={this.props.global.editing}
                    onPaste={Description.cleanse}  onBlur={this.handleEdit} class="home-description-changing-markets"
                    dangerouslySetInnerHTML={{ __html: this.props.global.home_description_para_2 }} />
                <ul id="home_description_list_1" class="home-description-description-list"
                    onPaste={Description.cleanse}  onBlur={this.handleEdit} contentEditable={this.props.global.editing}
                    dangerouslySetInnerHTML={{ __html: this.props.global.home_description_list_1 }} />
            </div>
        );
    }
}
export default connect(Description.mapStateToProps)(Description);