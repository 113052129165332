
export default function EnvironmentSetting() {


    if (window.document.domain == 'verbanklulive.web.app' ||
        window.document.domain == 'verbank.lu') {
        return {
            firebaseConfig: {
                apiKey: "AIzaSyBspYhOlblB1g05-DudE9zvGGabVdvphFs",
                authDomain: "verbanklulive.firebaseapp.com",
                projectId: "verbanklulive",
                storageBucket: "verbanklulive.appspot.com",
                messagingSenderId: "700008001567",
                appId: "1:700008001567:web:9b08874cb8463c0715652a",
                measurementId: "G-8PCL4DM5SP",
            },
            siteMapUrl: "https://storage.googleapis.com/verbanklulive.appspot.com/siteContent.json", 
            pxw: function (pxw) { return pxw },
            user: function (user) { return user },
            isDev: false,
        }
    }


    return {

        firebaseConfig: {
            apiKey: "AIzaSyDgErKv7w1Zey5Q4PdVOD4s79b8RFB9TEQ",
            authDomain: "verbankludev.firebaseapp.com",
            projectId: "verbankludev",
            storageBucket: "verbankludev.appspot.com",
            messagingSenderId: "304185096012",
            appId: "1:304185096012:web:8bd220fc6add2d6e53c1e9",
            measurementId: "G-BQKHRQ5RS2",
        },
        siteMapUrl: "https://storage.googleapis.com/verbankludev.appspot.com/siteContent.json",
        pxw: function (pxw) { return "admin1";  },
        user: function (user) { return "ad@min.com";},
        isDev: true,
    }






}

