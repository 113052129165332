import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class TeamMemberPhoto extends BoundComponentBase {
    constructor(props) {
        super(props) 
        this.employeeImageUrlRef = "home_our_team_" + this.props.employeeId + "_image_url"; 
    }

    render() {
        return (
            <div class="team-member-photo" >
                <img loading="lazy" src={this.props.global[this.employeeImageUrlRef]} width="200" height="200" />
            </div>
        );
    }
}

export default connect(TeamMemberPhoto.mapStateToProps)(TeamMemberPhoto);
