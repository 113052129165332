import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class WhatWeDo extends BoundComponentBase {

    render() {
        return (
            <div class="what-we-do" >
                <div class="left">
                    <img src="/images/office.png" alt="Glass Buildings" />
                </div>
                <div class="right">
                    <div class="description">
                        <h2 id="what_we_do_header" contentEditable={this.props.global.editing}
                            onPaste={WhatWeDo.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.what_we_do_header }} />
                        <p id="what_we_do_description" contentEditable={this.props.global.editing}
                            onPaste={WhatWeDo.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.what_we_do_description }} />
                        <ul id="what_we_do_bullets" contentEditable={this.props.global.editing}
                            onPaste={WhatWeDo.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.what_we_do_bullets }} />
                    </div>
                    <div class="button-panel">
                        <p class="centred-button">
                            {this.props.global.editing ?
                                <a class="customButtonOrange" id="what_we_do_button" contentEditable="true" onPaste={WhatWeDo.cleanse}  onBlur={this.handleEdit}
                                    dangerouslySetInnerHTML={{ __html: this.props.global.what_we_do_button }} /> :
                                <Link class="customButtonOrange" to="/contact-us" dangerouslySetInnerHTML={{ __html: this.props.global.what_we_do_button }} />
                            }
                        </p>
                    </div>
                    {/*<h1 style="font-size: 40px; padding-top: 82px;">Verbank Securities</h1>*/}
                    {/*<p style="font-size: 18px; font-weight: 400; line-height: normal;">Advises and guides you through complex financial challenges:</p>*/}
                    {/*<ul>*/}
                    {/*    <li style="font-size: 18px; font-weight: 400; line-height: normal;">With full transparency through comprehensive data analysis services, and personalized capital structure management*/}
                    {/*        <p></p>*/}
                    {/*    </li>*/}
                    {/*    <li style="font-size: 18px; font-weight: 400; line-height: normal;">We get involved to know exactly what your current and future needs are or will be during each stage, and we accompany you throughout the process*/}
                    {/*        <p></p>*/}
                    {/*    </li>*/}
                    {/*    <li style="font-size: 18px; font-weight: 400; line-height: normal;">We offer you management advice to search and obtain private financing (medium to long term) with an emphasis on the issuance of listed bonds in the Grand Duchy of Luxembourg*/}
                    {/*        <p></p>*/}
                    {/*    </li>*/}
                    {/*    <li style="font-size: 18px; font-weight: 400; line-height: normal;">Regarding the discussions with potential investors, we guide you to focus exclusively on institutional investors, leaving aside the retail market*/}
                    {/*        <p></p>*/}
                    {/*    </li>*/}
                    {/*    <li style="font-size: 18px; font-weight: 400; line-height: normal;">We believe we are in a privileged position to advise you in this process thanks to our extensive trajectory in the origination and obtaining of medium and long-term structured financing*/}
                    {/*        <p></p>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                </div>
            </div>
        );
    }
}

export default connect(WhatWeDo.mapStateToProps)(WhatWeDo);
