

import EnvironmentSetting from './environmentSetting';

export default function GetContent(success) {
     
    fetch(EnvironmentSetting().siteMapUrl + '?a=' + Math.random(), { mode: 'cors' }
    )
        .then(function (response) {
              
            response.text().then(function (text) {

                var data = JSON.parse(text);
                data.articles  = [
                        {
                            title: "US + Brazil Financial News (5/31)",
                            date: "31st May",
                            content: "bla blah",
                            preview: "UNITED STATES   The main event this week is the May/18 jobs report, due out on Friday. US growth is accelerating after the Q1/18 slowdown. The..",
                            url: "/US-Brazil-Financial-News-5-31",
                        },
                        {
                            title: "US + Brazil Financial News (5/15)",
                            date: "15th May",
                            content: "bla blah",
                            preview: "UNITED STATES   The main data this week will be retail sales for April, out on Tuesday. It should show healthy growth, with the \"control group\"..",
                            url: "/US-Brazil-Financial-News-5-15",
                        },
                        {
                            title: "US + Brazil Financial News (5/8)",
                            date: "8th May",
                            content: "bla blah",
                            preview: "UNITED STATES   What did  we learn from last week\'s FOMC meeting and NFP reading? First, we learned that the economy is doing well, and the..",
                            url: "/US-Brazil-Financial-News-5-8",
                        },
                        {
                            title: "Brazil Financial News (5/2)",
                            date: "2nd May",
                            content: "bla blah",
                            preview: "BRAZIL   IMPORTANT NOTICES: This report is a general discussion of certain economic and geopolitical trends and forecasts.  It does not constitute investment advice of..",
                            url: "/Brazil-Financial-News-5-2",
                        },
                        {
                            title: "US + Brazil Financial News (4/24)",
                            date: "24th April",
                            content: "bla blah",
                            preview: "UNITED STATES   This week we get the advanced estimate of Q1/18 GDP. The results will disappoint (see below) but they are not a good indication..",
                            url: "/US-Brazil-Financial-News-4-24",
                        },
                        {
                            title: "US + Brazil Financial News (4/16)",
                            date: "16th April",
                            content: "bla blah",
                            preview: "UNITED STATES   The minutes of the FOMC\'s March 21st meeting came out last week: The Committee was sanguine on growth and confident on the pickup..",
                            url: "/US-Brazil-Financial-News-4-16",
                        },
                        {
                            title: "US + Brazil Financial News (4/2)",
                            date: "2nd April",
                            content: "bla blah",
                            preview: "UNITED STATES   The main event this week is Friday\'s payroll report. Although some attenuation is likely, after February\'s torrid pace, it still should be a..",
                            url: "/US-Brazil-Financial-News-4-2",
                        },
                        {
                            title: "US + Brazil Financial News (3/21)",
                            date: "21st March",
                            content: "bla blah",
                            preview: "UNITED STATES   This will be Jerome Powell\'s first FOMC as chairman. Regarding the rate decision, he made his views clear. \"From headwinds to tailwinds\" made-and..",
                            url: "/US-Brazil-Financial-News-3-21",
                        },
                        {
                            title: "US + Brazil Financial News (3/13)",
                            date: "13th March",
                            content: "bla blah",
                            preview: "UNITED STATES   No shortage of economic and financial headlines last week. Tariffs; and, of course, non-farm payrolls (NFP): 313k in Feb/18-108k above consensus, the fastest..",
                            url: "/US-Brazil-Financial-News-3-13",
                        },
                        {
                            title: "US + Brazil Financial News (3/9)",
                            date: "9th March",
                            content: "bla blah",
                            preview: "UNITED STATES   While the jobs report is the focal point of the week, the revised/final productivity numbers for 2017 also merit attention. The dip in..",
                            url: "/US-Brazil-Financial-News-3-9",
                        },

                    ];
                data.editing = false; 
                success(data); 
            });
        }).catch(function (err) {
            //alert(err);
        });
}

