import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore } from "redux";
import { Provider } from "react-redux";
import contentReducer from './dataStore/contentReducer';
import GetContent from './dataStore/getContent';

const store = createStore(contentReducer); 
console.log(new Date().getSeconds());
 
GetContent(function (data) {
    data.editingMode = false;
    data.contentLoading = false;
    if (window.location.pathname == "/edit") {
        data.editing = true;
        data.editingMode = true;
    }
    console.log(new Date().getSeconds());
    store.dispatch({
        type: "UPSERT",
        payload: data
    });
});


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


