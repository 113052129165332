import AboutUsItem from './AboutUsItem';
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase';
import { Link } from "react-router-dom";

class AboutUs extends BoundComponentBase {

    render() {
        return (
            <div class="home-about-us" >
                <h2 id="home_about_us_heading_1" contentEditable={this.props.global.editing}
                    onPaste={AboutUs.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_about_us_heading_1 }} />
                <h3 id="home_about_us_subheading_1" contentEditable={this.props.global.editing}
                    onPaste={AboutUs.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_about_us_subheading_1 }} />
                <div>
                    <AboutUsItem itemId="1" />
                    <AboutUsItem itemId="2"/>
                    <AboutUsItem itemId="3"/>
                </div>
                <h3 id="home_about_us_subheading_2" contentEditable={this.props.global.editing} onPaste={AboutUs.cleanse}  onBlur={this.handleEdit}
                    dangerouslySetInnerHTML={{ __html: this.props.global.home_about_us_subheading_2 }} />
                <div>
                    <AboutUsItem itemId="4"/>
                    <AboutUsItem itemId="5"/>
                    <AboutUsItem itemId="6"/>
                    <AboutUsItem itemId="7"/>
                </div>
                <p class="centred-button">
                    { this.props.global.editing   ?
                        <a class="customButton" id="home_about_us_button" contentEditable="true" onPaste={AboutUs.cleanse}  onBlur={this.handleEdit}
                            dangerouslySetInnerHTML={{ __html: this.props.global.home_about_us_button }} /> :
                        <Link class="customButton" to="/about-us" dangerouslySetInnerHTML={{ __html: this.props.global.home_about_us_button }}   />
                    } 
                </p>
            </div>
        );
    }
}

export default connect(AboutUs.mapStateToProps)(AboutUs);
