import { Link } from "react-router-dom";
import OurTeamItem from './OurTeamItem'
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
 
class OurTeam extends BoundComponentBase {

    render() {
        return (
            <div class="home-our-team" >
                <h2 id="home_our_team_heading" contentEditable={this.props.global.editing}
                    onPaste={OurTeam.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_our_team_heading }} />
                <p class="home-our-team-description" id="home_our_team_description" contentEditable={this.props.global.editing}
                    onPaste={OurTeam.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_our_team_description }} />
                <div>
                    <OurTeamItem employeeId="1"/>
                    <OurTeamItem employeeId="2"/>
                    <OurTeamItem employeeId="3"/>
                </div>
                <p class="centred-button"> 
                    {this.props.global.editing ?
                        <a class="customButtonOrange" id="home_our_team_button" contentEditable="true" onPaste={OurTeam.cleanse}  onBlur={this.handleEdit}
                            dangerouslySetInnerHTML={{ __html: this.props.global.home_our_team_button }} /> :
                        <Link class="customButtonOrange" to="/our-team" dangerouslySetInnerHTML={{ __html: this.props.global.home_our_team_button }} />
                    }

                </p>
            </div>
        );
    }
}

export default connect(OurTeam.mapStateToProps)(OurTeam);
