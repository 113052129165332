import   { Component } from "react";
import { connect } from "react-redux";

class BoundComponentBase extends Component {
    constructor(props) {
        super(props)
        this.state = ({ ...props });
    }

    static mapStateToProps = (state) => {
        return {
            global: state,
        };
    };


    static cleanse = (e) => {
        e.preventDefault();
        var text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    }




    //static assign = (item, path) => {
    //    var pathArray = path.split(".");

    //    if pathArray.length = 1 {
    //        if pathArray.
            

    //    }



    //}



    handleEdit = (e) => {
        const { innerHTML, id } = e.target;
        this.props.global[id] = innerHTML;
        this.pushModel();
    }

    setProperty = (key, value) => {
        this.props.global[key] = value;
        this.pushModel();
    }


    pushModel() {
        this.props.dispatch({
            type: "UPSERT",
            payload: { ...this.props.global },
        });
    }
}
export default BoundComponentBase;