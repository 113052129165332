import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Home from './components/home/Home'
import AboutUs from './components/aboutUs/AboutUs'
import WhatWeDo from './components/whatWeDo/WhatWeDo'
import ContactUs from './components/contactUs/ContactUs'
import News from './components/news/News'
import NewsArticle from './components/news/NewsArticle'
import OurTeam from './components/ourTeam/OurTeam'
import BoundComponentBase from './components/base/BoundComponentBase'
import { connect } from "react-redux";
import ScrollToTop from './helpers/ScrollToTop';

import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


class App extends BoundComponentBase {
    constructor(props) {
        super(props);

        this.state = { loading: true, preLoading: true, minLoading: true }

        this.newArticles = [
            {
                title: "US + Brazil Financial News (5/31)",
                date: "31st May",
                content: "bla blah",
                preview: "UNITED STATES   The main event this week is the May/18 jobs report, due out on Friday. US growth is accelerating after the Q1/18 slowdown. The..",
                url: "/US-Brazil-Financial-News-5-31",
            },
            {
                title: "US + Brazil Financial News (5/15)",
                date: "15th May",
                content: "bla blah",
                preview: "UNITED STATES   The main data this week will be retail sales for April, out on Tuesday. It should show healthy growth, with the \"control group\"..",
                url: "/US-Brazil-Financial-News-5-15",
            },
            {
                title: "US + Brazil Financial News (5/8)",
                date: "8th May",
                content: "bla blah",
                preview: "UNITED STATES   What did  we learn from last week\'s FOMC meeting and NFP reading? First, we learned that the economy is doing well, and the..",
                url: "/US-Brazil-Financial-News-5-8",
            },
            {
                title: "Brazil Financial News (5/2)",
                date: "2nd May",
                content: "bla blah",
                preview: "BRAZIL   IMPORTANT NOTICES: This report is a general discussion of certain economic and geopolitical trends and forecasts.  It does not constitute investment advice of..",
                url: "/Brazil-Financial-News-5-2",
            },
            {
                title: "US + Brazil Financial News (4/24)",
                date: "24th April",
                content: "bla blah",
                preview: "UNITED STATES   This week we get the advanced estimate of Q1/18 GDP. The results will disappoint (see below) but they are not a good indication..",
                url: "/US-Brazil-Financial-News-4-24",
            },
            {
                title: "US + Brazil Financial News (4/16)",
                date: "16th April",
                content: "bla blah",
                preview: "UNITED STATES   The minutes of the FOMC\'s March 21st meeting came out last week: The Committee was sanguine on growth and confident on the pickup..",
                url: "/US-Brazil-Financial-News-4-16",
            }, 
            {
                title: "US + Brazil Financial News (4/2)",
                date: "2nd April",
                content: "bla blah",
                preview: "UNITED STATES   The main event this week is Friday\'s payroll report. Although some attenuation is likely, after February\'s torrid pace, it still should be a..",
                url: "/US-Brazil-Financial-News-4-2",
            },
            {
                title: "US + Brazil Financial News (3/21)",
                date: "21st March",
                content: "bla blah",
                preview: "UNITED STATES   This will be Jerome Powell\'s first FOMC as chairman. Regarding the rate decision, he made his views clear. \"From headwinds to tailwinds\" made-and..",
                url: "/US-Brazil-Financial-News-3-21",
            },
            {
                title: "US + Brazil Financial News (3/13)",
                date: "13th March",
                content: "bla blah",
                preview: "UNITED STATES   No shortage of economic and financial headlines last week. Tariffs; and, of course, non-farm payrolls (NFP): 313k in Feb/18-108k above consensus, the fastest..",
                url: "/US-Brazil-Financial-News-3-13",
            },
            {
                title: "US + Brazil Financial News (3/9)",
                date: "9th March",
                content: "bla blah",
                preview: "UNITED STATES   While the jobs report is the focal point of the week, the revised/final productivity numbers for 2017 also merit attention. The dip in..",
                url: "/US-Brazil-Financial-News-3-9",
            },

        ];
    }


    waitForImages = (urls, callback) => {
        let loaded = 0;
        urls.forEach((src) => {
            var img = new Image();
            img.onload = () => {
                if (++loaded == urls.length && callback) {
                    callback();
                }
            };
            img.onerror = (err) => {
                console.log(err);
            };
            img.src = src;
        });
    };
     
    componentDidMount() {


        setTimeout(() => { this.setState({ ...this.state, minLoading: false }) }, 1000);

        this.waitForImages(["/images/largeLogo.jpg", "/images/spinner.gif"],
            () => { this.setState({ ...this.state, preLoading: false }); }
        );
        
        this.waitForImages(["/images/antonio.png"
            , "/images/clock.png"
            , "/images/emilano.png"
            , "/images/facebook.jpg"
            , "/images/favicon.png"
            , "/images/globe.png"
            , "/images/imageBanner.jpg"
            , "/images/instagram.jpg"
            , "/images/jorge.png"
            , "/images/linkedIn.jpg"
            , "/images/location.png"
            , "/images/network.png"
            , "/images/office.png"
            , "/images/people.png"
            , "/images/person.png"
            , "/images/smallLogo.jpg"
            , "/images/team.png"
            , "/images/twitter.jpg"
        ]
            ,

            () => { this.setState({ ...this.state, loading: false }); }
        );

        setTimeout(() => { this.setState({ ...this.state, loading: false }) }, 10000);

    }

    render() {
        if (this.state.loading || this.state.minLoading || this.props.global.contentLoading) {
            return <div style={{ visibility: this.state.preLoading  ? "hidden" : "visible" }} class="loading">
                <img class="logo" src="/images/largeLogo.jpg" /> 
                <img class="spinner" src="/images/spinner.gif" />
            </div>
        }
        return (
            <Router>
                <div class="page-layout">
                    <Header />
                    <TransitionGroup>
                        <CSSTransition
                            timeout="300"
                            classNames="fade">
                            <ScrollToTop>
                                <Switch>
                                    <Route path="/about-us">
                                        <AboutUs />
                                    </Route>
                                    <Route path="/what-we-do">
                                        <WhatWeDo />
                                    </Route>
                                    <Route path="/our-team">
                                        <OurTeam />
                                    </Route>
                                    <Route path="/contact-us">
                                        <ContactUs />
                                    </Route>
                                    <Route path="/news">
                                        <News articles={this.props.global.articles} />
                                    </Route>
                                    {this.props.global.articles.map((item, index) => (
                                        <Route path={item.url}>
                                            <NewsArticle articleId={index} article={item}/>
                                        </Route>
                                    ))}
                                    <Route path="/">
                                        <Home />
                                    </Route>
                                </Switch>
                            </ScrollToTop>
                        </CSSTransition>
                    </TransitionGroup>

                    <Footer />
                </div>
            </Router >
        );
    }
}

export default connect(App.mapStateToProps)(App);