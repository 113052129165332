
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class AboutUsItem extends BoundComponentBase {
    constructor(props) {
        super(props)
        this.aboutUsTitle = "home_about_us_" + this.props.itemId + "_title";
        this.aboutUsImageUrl = "home_about_us_" + this.props.itemId + "_image_url";
        this.aboutUsDescription = "home_about_us_" + this.props.itemId + "_descripion";
    }

    render() {  
        return (
            <div class="home-about-us-item">
                <img src={this.props.global[this.aboutUsImageUrl]} />
                <span class="home-about-us-item-header" id={this.aboutUsTitle} contentEditable={this.props.global.editing}
                    onPaste={AboutUsItem.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global[this.aboutUsTitle] }}/>
                <p class="home-about-us-item-content" id={this.aboutUsDescription} contentEditable={this.props.global.editing}
                    onPaste={AboutUsItem.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global[this.aboutUsDescription] }} />
            </div>
        );
    }
}

export default connect(AboutUsItem.mapStateToProps)(AboutUsItem);
