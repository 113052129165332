import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class NewsItem extends BoundComponentBase {
    constructor(props) {
        super(props);

    }



    render() {
        return (
            <div class="news-item">
                <div class="news" >
                    <h3 id="news_header" >
                        {this.props.article.title} </h3>
                </div>
                <p class="date">
                    {this.props.article.date}  </p>
                <p class="description">
                    {this.props.article.preview}
                </p>
                <p class="buttonSection">
                    <Link class="customButtonOrangeSmall" to={this.props.article.url} >Read More {">"}</Link>
                </p>
            </div>
        );
    }
}

export default connect(NewsItem.mapStateToProps)(NewsItem);
