
import ImageBanner from './ImageBanner'
import Description from './Description'
import AboutUs from './AboutUs'
import WhatWeDo from './WhatWeDo'
import OurTeam from './OurTeam'
import ContactUs from './ContactUs'

function Home() {
    return (
        <div>
            <ImageBanner />
            <Description /> 
            <AboutUs />
            <WhatWeDo />
            <OurTeam />
            <ContactUs />   
        </div>
    );
}

export default Home;
