
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import NewsItem from './NewsItem'

class News extends BoundComponentBase {
    constructor(props) {
        super(props);

        this.newArticles = [
            {
                title: "Home",
                url: "/"
            },
            {
                title: "About Us",
                url: "/about-us"
            },
            {
                title: "What we do",
                url: "/what-we-do"
            },
            {
                title: "Our Team",
                url: "/our-team"
            },
            {
                title: "Contact Us",
                url: "/contact-us"
            },
            {
                title: "News",
                url: "/news"
            },
        ];


    }



    render() {
        return (
            <div class="news">
                <div  >
                    <h2 id="news_header" contentEditable={this.props.global.editing}
                        onPaste={News.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.news_header }} />

                </div>
                <div class="news-items"> 
                    {this.props.articles.map((item, index) => {

                        if (index == 3) {
                            return <></>

                        }
                        return( 
                            <NewsItem article={item} />
                        )
                    } 
                    )}
                </div>
            </div>
        );
    }
}

export default connect(News.mapStateToProps)(News);
