
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class NewsArticle extends BoundComponentBase {
    constructor(props) {
        super(props);
        this.articleTitle = "articles_" + this.props.articleId + "_title";
        this.articleContent = "articles_" + this.props.articleId + "_content";
    }



    render() {
        return (
            <div class="news-article">
                <div>
                    <p class="title" >  {this.props.article.title} </p>
                </div>
                <div class="content"   >
                    <p id={this.articleTitle} contentEditable={this.props.global.editing}
                        onPaste={NewsArticle.cleanse} onBlur={this.handleEdit} 
                         dangerouslySetInnerHTML={{ __html: this.props.global[this.articleTitle] }} />
                </div>
            </div>
        );
    }
}

export default connect(NewsArticle.mapStateToProps)(NewsArticle);
