import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import OurTeamItem from './OurTeamItem'

class OurTeam extends BoundComponentBase {

    render() {
        return (
            <div class="our-team" >
                <div class="our-team-description">
                    <h2 id="our_team_heading" contentEditable={this.props.global.editing}
                        onPaste={OurTeam.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.our_team_heading }} />
                    <p id="our_team_desc" contentEditable={this.props.global.editing}
                        onPaste={OurTeam.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.our_team_desc }} />
                </div>
                <div class="our-team-items">
                    <OurTeamItem employeeId="1" />
                    <OurTeamItem employeeId="2" />
                    <OurTeamItem employeeId="3" />
                </div>
            </div>
        );
    }
}

export default connect(OurTeam.mapStateToProps)(OurTeam);
